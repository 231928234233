$opc_update: true;
$appt_booking: true;
$text-direction: rtl;
$rdirection: left;
$ldirection: right;
$appt_booking_phone_field_direction-change: true;
$password_strengthen: true;

$AssistantHeavy: 'Assistant-Heavy', Helvetica, Arial, Sans-Serif;
$AssistantRegular: 'Assistant-Regular', Helvetica, Arial, Sans-Serif;
$AssistantLight: 'Assistant-Light', Helvetica, Arial, Sans-Serif;
$AssistantExtraBold: 'Assistant-Extra-Bold', Helvetica, Arial, Sans-Serif;

$ano-bold_regular-font: $AssistantRegular;
$ano_regular-font: $AssistantRegular;
$ano-black_regular-font: $AssistantExtraBold;
$headline-font-family: $AssistantRegular;
$headline-ano-black-font: $ano-bold_regular-font;

// 2020 styling refresh.
$cr20: true;
$fonts_update: true;
$gnav_update: true;

// 2021 styling refresh.
$cr21: true;
// hide reserve widget for launch
$hide-rating-count--spp: true;

$hide-notify-me-btn: true;
